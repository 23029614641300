import React from "react";
import PointIcon from "../../../../assets/images/vector/components/common/mainPage-home_01.svg";
import "./index.scss";

const ReasonsToUseItem = ({ text }) => (
  <div className="PgAT-ReasonsToUse__Item">
    <img src={PointIcon} alt="-" />
    <p>{text}</p>
  </div>
);

export default ReasonsToUseItem;
