import React from "react";
import POINT_ICON from "../../../../assets/images/vector/components/common/group-copy.svg";
import "./index.scss";

const POINTS_TEXT = [
  "Learn how to start selling well-known, proven products on Amazon",
  "Receive targeted product ideas that have low investment costs and low competition",
  "Discover keywords that will ensure you hit significant profit margins with PPC campaigns",
];

const Points = () => (
  <ul className="PgAT-HI-P">
    {POINTS_TEXT.map((text, i) => (
      <li key={i} className="PgAT-HI-P__item">
        <img src={POINT_ICON} alt="" />
        <p className="PgAT-HI-P__text">{text}</p>
      </li>
    ))}
  </ul>
);

export default Points;
