import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Info = ({ title, infoList }) => (
  <div className="PgAT-T-I">
    <p className="PgAT-T-I__title">{title}</p>
    <ul className="PgAT-T-I-infoList">
      {infoList.map(
        ({ title, firstText, list, afterListText, listParagraphs }, i) => (
          <li key={i} className="PgAT-T-I-item">
            <p className="PgAT-T-I-item__title">{title}</p>
            {firstText && (
              <p className="PgAT-T-I-item__firstText">{firstText}</p>
            )}
            {list && (
              <ul className="PgAT-T-I-item-list">
                {list.map((item) => (
                  <li key={item} className="PgAT-T-I-item-list__text">
                    {item}
                  </li>
                ))}
              </ul>
            )}
            {afterListText && (
              <p className="PgAT-T-I-item__afterListText">{afterListText}</p>
            )}
            {listParagraphs && (
              <ul className="PgAT-T-I-item-listParagraphs">
                {listParagraphs.map((item) => (
                  <li key={item} className="PgAT-T-I-item-listParagraphs__text">
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </li>
        )
      )}
    </ul>
  </div>
);
Info.propTypes = {
  title: PropTypes.string,
  infoList: PropTypes.array,
};
export default Info;
