import React from "react";
import SVGToolKitLogo from "../../../assets/images/vector/pages/amazon-toolkit/DealsBunner/toolkit-logo.svg";
import SVGToolKitLogoTab from "../../../assets/images/vector/pages/amazon-toolkit/DealsBunner/toolkit-logo@tab.svg";
import SVGToolKitLogoMob from "../../../assets/images/vector/pages/amazon-toolkit/DealsBunner/toolkit-logo@mob.svg";
import { getTrueClasses } from "../../../helpers";
import Close from "./Close";
import "./index.scss";

const DealsBanner = (props) => {
  const { isShowDeals, clickOpenHandler, isBottomLine, hideCloseButton } =
    props;
  if (!isShowDeals) return null;

  return (
    <div
      className={getTrueClasses(
        "PgAT-DealsBundle-section",
        "PgAT-DealsBundle-section_fixed",
        isBottomLine && "PgAT-DealsBundle-section_stay"
      )}
      onClick={clickOpenHandler}
    >
      <div className="PgAT-DealsBundle-container">
        <picture className="PgAT-DealsBundle__logo">
          <source media="(max-width: 767px)" srcSet={SVGToolKitLogoMob} />
          <source
            media="(min-width: 768px) and (max-width: 991px)"
            srcSet={SVGToolKitLogoTab}
          />
          <source media="(min-width: 992px)" srcSet={SVGToolKitLogo} />
          <img src={SVGToolKitLogo} alt="img" loading="lazy" />
        </picture>
        <p className="PgAT-DealsBundle__title PgAT-DealsBundle__info">
          Launch Your Own Product Brand on Amazon
        </p>
        <p className="PgAT-DealsBundle__description PgAT-DealsBundle__info">
          with a Сomplete AMZScout Toolset
        </p>
        <button className="PgAT-DealsBundle__cta">SEE DETAILS</button>
        <Close hideCloseButton={hideCloseButton} />
      </div>
    </div>
  );
};

export default DealsBanner;
