import React from "react";
import PNG_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@2x.png";
import PNG_1200_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@1200_2x.png";
import PNG_992_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@992_2x.png";
import PNG_Mob_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@mob_2x.png";

import WEBP_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@2x.webp";
import WEBP_1200_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@1200_2x.webp";
import WEBP_992_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@992_2x.webp";
import WEBP_Mob_2x from "../../../../assets/images/raster/pages/amazon-toolkit/HomeIntro/screen@mob_2x.webp";
import "./index.scss";

const Screen = () => (
  <div className="PgAT-HI-S">
    <picture>
      <source type="image/webp" media="(min-width: 1365px)" srcSet={WEBP_2x} />
      <source media="(min-width: 1365px)" srcSet={PNG_2x} />
      <source
        type="image/webp"
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={WEBP_1200_2x}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={PNG_1200_2x}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 1199px)"
        srcSet={WEBP_992_2x}
      />
      <source
        media="(min-width: 768px) and (max-width: 1199px)"
        srcSet={PNG_992_2x}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={WEBP_Mob_2x}
      />
      <source media="(max-width: 767px)" srcSet={PNG_Mob_2x} />
      <img src={PNG_2x} alt="" />
    </picture>
  </div>
);

export default Screen;
