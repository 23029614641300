import React from "react";
import PropTypes from "prop-types";
import CloseWhiteIcon from "../../../../assets/images/vector/components/common/close_white.svg";
import "./index.scss";

const Close = ({ hideCloseButton }) => {
  if (hideCloseButton) return null;

  return (
    <button
      className="PgAT-DealsBundle__close btn_clear PgAT-DealsBundle__closeJS"
      id="js-close-panel"
    >
      <img
        className="PgAT-DealsBundle__closeJS"
        src={CloseWhiteIcon}
        alt="X"
        loading="lazy"
      />
    </button>
  );
};
Close.propTypes = {
  hideCloseButton: PropTypes.bool,
};
export default Close;
