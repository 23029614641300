import React from "react";
import "./index.scss";

const EmptySpace = ({ isShow }) => {
  if (!isShow) return null;

  return <section className="PgAT-ES" />;
};

export default EmptySpace;
