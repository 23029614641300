const RATING_NOW_CONTENT = {
  isSecondTrust: true,
  trustText: "Based on Trustpilot reviews",
  posts: [
    {
      title: "This is a great tool for working...",
      description: "This is a great tool for working on Amazon. I have been using AMZ Scout for 3 months now. It is very comfortable. All data in one place. Highly recommended."
    },
    {
      title: "Fantastic tool for product research...",
      description: "Fantastic tool for product research. Packed with useful features to ensure you find the ideal product."
    },
    {
      title: "AMZScout support is great... ",
      description: "AMZScout support is great. I loved the tool and features. Overall going good and strongly recommend people try it if you are in the product hunt phase."
    }
  ]
};
export default RATING_NOW_CONTENT;