import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import { getTrueClasses } from "../../../../helpers";
import FormBlockContainer from "../../../../containers/common/forms/FormBlockContainer";
import { PAGE_OPTIONS, PAGES } from "../../../../helpers/constants";
import "./index.scss";

const FormWithPicture = (props) => {
  const {
    content: { jpeg, jpeg2x, webp, webp2x, title },
    customClass,
    formRef,
    page,
    redirectUrl,
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <section
      ref={formRef}
      className={getTrueClasses(
        "FormWithPicture-form",
        customClass,
        isAuth && "FormWithPicture-form-secondState"
      )}
    >
      <div className="FormWithPicture-form__img">
        <picture>
          <source type="image/webp" srcSet={`${webp} 1x, ${webp2x} 2x`} />
          <img srcSet={`${jpeg} 1x, ${jpeg2x} 2x`} alt="" />
        </picture>
      </div>
      <FormBlockContainer
        page={page ?? PAGES.AMAZON_SELLERS_BUNDLE}
        title={title}
        buttonTextFirstState="Try AMZScout For Free!"
        buttonTextSecondState="Try AMZScout For Free!"
        redirectUrl={
          redirectUrl ??
          PAGE_OPTIONS[PAGES.AMAZON_SELLERS_BUNDLE].REDIRECTED_URL
        }
      />
    </section>
  );
};

FormWithPicture.propTypes = {
  content: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FormWithPicture;
