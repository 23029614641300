import React from "react";
import TOOLS_CONTENT from "./index.content";
import Info from "./Info";
import ScreenFull from "../../common/UI/ScreenFull";
import "./index.scss";

const Tools = () => (
  <section className="PgAT-T">
    <p className="PgAT-T__subtitle">
      Blast off your Amazon journey by following these steps for running a
      successful business with a
    </p>
    <h1 className="PgAT-T__title">
      Comprehensive Amazon Toolkit for Sellers
    </h1>
    <div className="container">
      <div className="PgAT-T-mainList">
        {TOOLS_CONTENT.map(({ screen, title, infoList }, index) => (
          <div key={index} className={`PgAT-T-row PgAT-T-row_r${index + 1}`}>
            <Info title={title} infoList={infoList} />
            <div className={`PgAT-T-S PgAT-T-S_s${index + 1}`}>
              <ScreenFull screen={screen} customClasses="PgAT-T-S__img" />
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Tools;
