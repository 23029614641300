import React from "react";

const PG_AT_REASONS_TO_USE_CONTENT = {
  firstText: <><b>No need for starting capital</b>, large stocks, or prior sales experience</>,
  secondText: <><b>No need to find new products</b> to sell, you can sell what is already on Amazon</>,
  thirdText: <><b>No need to look for foreign suppliers</b> or import stocks of goods across the ocean</>,
  fourthText: <><b>No need to promote your product</b> to the top, it’s already seeing decent sales - you just need to
    connect with these listings and start selling immediately</>
};
export default PG_AT_REASONS_TO_USE_CONTENT;

