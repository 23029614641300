import React from "react"

import Jpg3 from "../../assets/images/raster/pages/amazon-toolkit/common/formPic3.jpg"
import Jpg3_2x from "../../assets/images/raster/pages/amazon-toolkit/common/formPic3@2x.jpg"
import Webp3 from "../../assets/images/raster/pages/amazon-toolkit/common/formPic3.webp"
import Webp3_2x from "../../assets/images/raster/pages/amazon-toolkit/common/formPic3@2x.webp"

export const PG_AT_CONTENT = {
  form3: {
    pic: Jpg3,
    pic2x: Jpg3_2x,
    webp: Webp3,
    webp2x: Webp3_2x,
    title:
      "Sign Up for a Free Trial of AMZScout to Find Profitable Amazon Products",
  },
}
