import React from "react";
import PG_AT_REASONS_TO_USE_CONTENT from "./index.content";
import ReasonsToUseItem from "./ReasonsToUseItem";
import "./index.scss";

const ReasonsToUse = () => {
  const { firstText, secondText, thirdText, fourthText } =
    PG_AT_REASONS_TO_USE_CONTENT;
  return (
    <section className="PgAT-ReasonsToUse">
      <div className="container">
        <div className="PgAT-ReasonsToUse__wrap">
          <h2 className="PgAT-ReasonsToUse__title">
            All of the Reasons You Should Start Using Dropshipping, Online
            Arbitrage, or Wholesale Right Now:
          </h2>
          <div className="PgAT-ReasonsToUse__points">
            <div className="PgAT-ReasonsToUse-list PgAT-ReasonsToUse-list_first">
              <ReasonsToUseItem text={firstText} />
              <ReasonsToUseItem text={secondText} />
            </div>
            <div className="PgAT-ReasonsToUse-list PgAT-ReasonsToUse-list_second">
              <ReasonsToUseItem text={thirdText} />
              <ReasonsToUseItem text={fourthText} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReasonsToUse;
