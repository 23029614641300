import React from "react";
import "./index.scss";

const Last = () => (
  <section className="PgAT-Last">
    <div className="container">
      <h2 className="PgAT-Last__title">
        AMZSCOUT OFFERS A COMPLETE SET OF PROVEN AMAZON TOOLS
      </h2>
      <div className="PgAT-Last__subtitle">
        Use AMZScout tools to grow your business with software for optimization,
        marketing, competitor data, and analytics.
      </div>
      <div className="PgAT-Last__text">
        Try this toolkit to help your Amazon business become more competitive,
        and find the best products so you can meet your goals, save time, and
        make money.
      </div>
    </div>
  </section>
);

export default Last;
